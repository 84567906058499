var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-question-wrapper"},[_c('div',{staticClass:"add-btn-wrapper"},[_c('h3',[_vm._v("Danh sách nhóm câu hỏi")]),_c('div',{staticClass:"add-btn"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewGroupQuestion()}}},[_vm._v("Tạo nhóm câu hỏi mới ")])],1)]),(_vm.groupQuestions.length === 0)?_c('v-alert',{attrs:{"value":true,"color":"warning","icon":"priority_high","outlined":""}},[_vm._v(" Chưa có dữ liệu hiển thị ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 custom-size-img",attrs:{"headers":_vm.headers,"items":_vm.groupQuestions},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.title)}})]}},{key:"item.group_question_type_id",fn:function(ref){
var item = ref.item;
return [(item.group_question_type_id === 1)?_c('div',[_vm._v("Nhóm chung")]):(item.group_question_type_id === 2)?_c('div',[_vm._v("Nhóm riêng")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewGroupQuestion(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteGroupQuestion(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.list",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewListQuestion(item)}}},[_vm._v(" Xem danh sách câu hỏi ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }