<template>
  <div class="group-question-wrapper">
    <div class="add-btn-wrapper">
      <h3>Danh sách nhóm câu hỏi</h3>
      <div class="add-btn">
        <v-btn color="primary" @click="viewGroupQuestion()"
        >Tạo nhóm câu hỏi mới
        </v-btn>
      </div>
    </div>
    <v-alert v-if="groupQuestions.length === 0" :value="true" color="warning" icon="priority_high" outlined>
      Chưa có dữ liệu hiển thị
    </v-alert>
  <v-data-table :headers="headers" :items="groupQuestions" class="elevation-1 custom-size-img" >
    <template v-slot:item.title="{ item }">
      <div v-html="item.title"></div>
    </template>
    <template v-slot:item.group_question_type_id="{ item }">
      <div v-if="item.group_question_type_id === 1">Nhóm chung</div>
      <div v-else-if="item.group_question_type_id === 2">Nhóm riêng</div>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="viewGroupQuestion(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          small
          @click="deleteGroupQuestion(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:item.list="{ item }">
      <v-btn
          small
          class="mr-2"
          @click="viewListQuestion(item)"
      >
        Xem danh sách câu hỏi
      </v-btn>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  GET_GROUP_QUESTION_LIST_BY_CONFIG,
    DELETE_GROUP_QUESTION,
} from "@/store/group-questions.module";

export default {
  components: {},
  data() {
    return {
      exam_config_id: this.$route.query.exam_config_id,
      headers: [
        {text: "Tên nhóm", value: "title"},
        {text: "Loại nhóm", value: "group_question_type_id"},
        {text: "Câu bắt đầu", value: "from"},
        {text: "Câu kết thúc ", value: "to"},
        {text: "Số lượng câu hỏi", value: "questions_count"},
        {text: "Thao tác ", value: "actions"},
        {text: "", value: "list"},
      ],
    };
  },
  computed: {
    ...mapGetters({
      groupQuestions: "groupQuestionListByConfig",
    }),
  },
  async created() {
    await this.fetchGroupQuestion();
  },

  methods: {
    fetchGroupQuestion(){
       this.$store.dispatch(GET_GROUP_QUESTION_LIST_BY_CONFIG, {
        exam_config_id: this.exam_config_id
      });
    },
    viewGroupQuestion(item) {
      let routeData = null;
      if (item) {
        routeData = this.$router.resolve({
          path: "/group-question/detail",
          query: {
            exam_config_id: this.exam_config_id,
            group_question_id: item.id
          },
        });
      } else {
        routeData = this.$router.resolve({
          path: "/group-question/detail",
          query: {exam_config_id: this.exam_config_id},
        });
      }
      window.open(routeData.href, '_self');
    },
    deleteGroupQuestion(groupQuestion) {
      confirm("Bạn có chắc chắn muốn xóa không?") &&
      this.$store
          .dispatch(DELETE_GROUP_QUESTION,  groupQuestion.id)
          .then((data) => {
            if (data.status) {
              this.fetchGroupQuestion()
            }
          });
    },
    viewListQuestion(item) {
      let routeData = this.$router.resolve({
        path: "/question/list",
        query: {
          exam_config_id: this.exam_config_id,
          group_question_id: item.id,
        },
      });
      window.open(routeData.href, '_self');
    }
  }
};
</script>


<style lang="scss">
.group-question-wrapper {
  padding: 20px;
}

.add-btn-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 10px;

  .add-btn {
    display: flex;
    justify-content: flex-end;

    button {
      width: 200px;
    }
  }
}
.custom-size-img {
  img {
    margin-top: 20px;
    width: 300px;
  }
}
</style>
